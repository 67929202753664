import { UIHeading4 } from '@cian/ui-kit';
import * as React from 'react';

import * as style from './style.css';

export interface ICoworkingAmenitiesGroupProps {
  title: string;
  children: React.ReactNode;
}

export const CoworkingAmenitiesGroup: React.FC<React.PropsWithChildren<ICoworkingAmenitiesGroupProps>> = ({
  title,
  children,
}) => {
  return (
    <div className={style['group']}>
      <UIHeading4 as="h4" color="black_100">
        {title}
      </UIHeading4>

      <div>{children}</div>
    </div>
  );
};
