import { UIHeading1 } from '@cian/ui-kit';
import * as React from 'react';

import * as style from './style.css';

export interface ICoworkingAmenitiesLayoutProps {
  children: React.ReactNode;
}

export const CoworkingAmenitiesLayout: React.FC<React.PropsWithChildren<ICoworkingAmenitiesLayoutProps>> = ({
  children,
}) => {
  return (
    <div className={style['container']}>
      <UIHeading1 as="h2" color="black_100">
        Включено в стоимость аренды
      </UIHeading1>

      <div className={style['wrapper']}>{children}</div>
    </div>
  );
};
