import { Button, LinkButton, UIHeading2, UIHeading4, UIText1, UIText2 } from '@cian/ui-kit';
import cx from 'clsx';
import * as React from 'react';

import * as styles from './BestPlaceReportDescription.css';
import { REPORT_FEATURES } from './constants';

export interface IBestPlaceReportDescriptionProps {
  priceWithoutDiscount: number | null;
  priceWithDiscount: number | null;
  reportExampleUrl: string;
  handleBuyReport: VoidFunction;
  handleExamineReportExample: VoidFunction;
  getReportPrices: VoidFunction;
}

export const BestPlaceReportDescription: React.FC<IBestPlaceReportDescriptionProps> = props => {
  const {
    priceWithoutDiscount,
    priceWithDiscount,
    reportExampleUrl,
    handleBuyReport,
    handleExamineReportExample,
    getReportPrices,
  } = props;

  React.useEffect(() => {
    getReportPrices();
  }, [getReportPrices]);

  const content = (() => {
    const numberOfItemsPerColumn = Math.ceil(REPORT_FEATURES.length / 2);

    const columns = REPORT_FEATURES.reduce(
      (acc: { columnA: JSX.Element[]; columnB: JSX.Element[] }, { Icon, text }, ind) => {
        const item = (
          <li className={styles['content-item']} key={ind}>
            <span className={styles['content-item-icon']}>{Icon}</span>
            <UIText1>{text}</UIText1>
          </li>
        );

        const isFirstColumnContent = ind + 1 <= numberOfItemsPerColumn;

        (isFirstColumnContent ? acc.columnA : acc.columnB).push(item);

        return acc;
      },
      { columnA: [], columnB: [] },
    );

    return (
      <ul className={styles['content']}>
        <div className={styles['content-column']}>{columns.columnA}</div>
        <div className={styles['content-column']}>{columns.columnB}</div>
      </ul>
    );
  })();

  return (
    <section className={styles['wrapper']}>
      <div className={styles['header']}>
        <span className={styles['report-icon']} />
        <span>
          <UIHeading2>Отчёт о привлекательности объекта</UIHeading2>
          <span className={cx(styles['header-description'])}>
            <UIText1>Узнайте, насколько помещение и район подходят для вашего бизнеса</UIText1>
          </span>
        </span>
      </div>
      <div className={styles['secondary-header']}>
        <UIHeading4>Что входит в PDF-отчёт</UIHeading4>
      </div>
      {content}
      <div className={cx(styles['footer'])}>
        <Button
          theme="fill_primary"
          beforeIcon={
            <span className={styles['price-wrapper']}>
              {priceWithDiscount ? (
                <UIText1 color="white_100" fontWeight="700">
                  Купить отчёт за {priceWithDiscount} &#x20BD;
                </UIText1>
              ) : (
                <UIText1 color="white_100" fontWeight="700">
                  Купить отчёт
                </UIText1>
              )}
              {priceWithoutDiscount && (
                <span className={styles['price-no-discount']}>
                  <UIText2 color="gray40_100">{priceWithoutDiscount} &#x20BD;</UIText2>
                </span>
              )}
            </span>
          }
          onClick={handleBuyReport}
        />

        <LinkButton
          theme={'fill_white_secondary'}
          beforeIcon={
            <UIText1 color="primary_100" fontWeight="700">
              Посмотреть пример отчёта
            </UIText1>
          }
          href={reportExampleUrl}
          download="report-example"
          target="_blank"
          rel="noreferrer"
          onClick={handleExamineReportExample}
        />
      </div>
    </section>
  );
};

BestPlaceReportDescription.displayName = 'BestPlaceReportDescription';
