import { UIHeading1 } from '@cian/ui-kit';
import cx from 'clsx';
import * as React from 'react';

import * as styles from './styles.css';

export interface ICommercialOfferSummaryInfoGroupProps {
  header: string;
  children: React.ReactNode;
}

export const CommercialOfferSummaryInfoGroup: React.FC<
  React.PropsWithChildren<ICommercialOfferSummaryInfoGroupProps>
> = ({ header, children }) => {
  return (
    <div data-testid="CommercialOfferSummaryInfoGroup" className={cx(styles['group-commercial'])}>
      <div className={styles['header']}>
        <UIHeading1 as="h2" color="black_100">
          {header}
        </UIHeading1>
      </div>

      <div>{children}</div>
    </div>
  );
};
