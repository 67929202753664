import {
  IconCommercialObject16,
  IconDynamicChart16,
  IconLike16,
  IconPin16,
  IconStore16,
  IconUser16,
  IconWallet16,
} from '@cian/ui-kit';
import * as React from 'react';

import { IReportFeature } from 'shared/components/BestPlaceAnalytics/components/BestPlaceReportDescription/constants';

export const WILL_KNOW_FEATURES: IReportFeature[] = [
  {
    Icon: <IconLike16 />,
    text: 'Оценку помещения',
  },
  {
    Icon: <IconUser16 />,
    text: 'Данные о трафике',
  },
  {
    Icon: <IconWallet16 />,
    text: 'Средний бюджет семьи по району',
  },
  {
    Icon: <IconStore16 />,
    text: 'Ликвидность помещения',
  },
];

export const WILL_HELP_FEATURES: IReportFeature[] = [
  {
    Icon: <IconPin16 />,
    text: 'Выбрать лучшее место для бизнеса',
  },
  {
    Icon: <IconCommercialObject16 />,
    text: 'Определить оптимальную арендную плату',
  },
  {
    Icon: <IconDynamicChart16 />,
    text: 'Рассчитать окупаемость и рентабельность помещения',
  },
];
