import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { subscribeBestPlaceAnalyticsStatusUpdate } from 'shared/actions/bestPlaceAnalytics/subscribeBestPlaceAnalyticsStatusUpdate';
import { proceedToCheckEmail } from 'shared/actions/bestPlaceReportModal';
import {
  selectBestPlaceAnalyticsReportPrices,
  selectBestPlaceReportLegalDocsUrl,
  selectCurrentUserEmail,
  selectReportUserEmail,
  selectPurchaseFormDisabled,
} from 'shared/selectors/bestPlaceAnalytics';

import { PurchaseForm } from '../../components/PurchaseForm';
import { useBestPlaceReportEmailContext } from '../../hooks/useBestPlaceReportEmailContext';

export const PurchaseFormContainer: React.FC = () => {
  const dispatch = useDispatch();
  const { amount, discount, discountPrice } = useSelector(selectBestPlaceAnalyticsReportPrices);
  const { reportEmail, setReportEmail } = useBestPlaceReportEmailContext();

  const transactionId = React.useMemo(() => {
    const url = new URL(window.location.href);

    return url.searchParams.get('transactionId');
  }, []);

  const reportUserEmail = useSelector(selectReportUserEmail);
  const currentUserEmail = useSelector(selectCurrentUserEmail);

  const userEmail = React.useMemo(() => {
    // Кейс, когда юзер возвращается на форму покупки со СЛЕДУЮЩЕГО шага.
    // У нас уже есть email, который он ранее вводил, его и используем.
    if (reportEmail) {
      return reportEmail;
    }

    if (transactionId) {
      /* istanbul ignore next */
      return reportUserEmail || '';
    }

    return currentUserEmail;
  }, [reportEmail, reportUserEmail, currentUserEmail, transactionId]);

  React.useEffect(() => {
    setReportEmail(userEmail);
  }, [setReportEmail, userEmail]);

  const legalDocsUrl = useSelector(selectBestPlaceReportLegalDocsUrl);

  const isFormControlsDisabled = useSelector(selectPurchaseFormDisabled);

  const onSubmit = React.useCallback(
    (email: string) => {
      setReportEmail(email);

      dispatch(proceedToCheckEmail());
    },
    [dispatch, setReportEmail],
  );

  const onUpdatePaymentStatus = React.useCallback(() => {
    if (transactionId) {
      dispatch(subscribeBestPlaceAnalyticsStatusUpdate(transactionId));
    }
  }, [dispatch, transactionId]);

  return (
    <PurchaseForm
      userEmail={reportEmail}
      legalDocsUrl={legalDocsUrl}
      beforePrice={amount}
      price={discountPrice}
      sale={discount}
      onSubmit={onSubmit}
      onUpdatePaymentStatus={onUpdatePaymentStatus}
      isDataLoading={isFormControlsDisabled}
    />
  );
};

PurchaseFormContainer.displayName = 'PurchaseFormContainer';
