import { useToggleState } from '@cian/react-utils';
import { IconArrowUpRight16, IconChevronDownSmall16, UIText1 } from '@cian/ui-kit';
import cx from 'clsx';
import React from 'react';

import { pluralize } from 'shared/utils/pluralize';

import styles from './OfferGroupByType.css';
import { OfferGroupByTypeProps } from './types';
import { getFriendlyArea } from '../../utils/getFriendlyArea';
import { getFriendlyPrice } from '../../utils/getFriendlyPrice';
import { getOtherOfferLabelPluralFactory } from '../../utils/getOtherOfferLabelPluralFactory';
import { OffersList } from '../OffersList';

export const OfferGroupByType: React.FC<OfferGroupByTypeProps> = props => {
  const { otherOffersGroup, onShowAllOffersClick, onListItemClick, isRent } = props;
  const { offerGroupType, otherOffers, offersCount, titleLink, minTotalArea, minPrice } = otherOffersGroup;

  const { state: isOffersVisible, toggle: toggleOffersVisibility } = useToggleState();

  const allOtherOffersText = pluralize(offersCount, ['объявление', 'объявления', 'объявлений']);

  const minGroupPriceText = getFriendlyPrice(minPrice, isRent, true);
  const minGroupTotalAreaText = getFriendlyArea(minTotalArea, true);

  const { label, shortLabel } = getOtherOfferLabelPluralFactory(offerGroupType);

  return (
    <div className={cx(styles['container'], { [styles['open']]: isOffersVisible })}>
      <div className={styles['group-container']}>
        <div className={styles['title-col']}>
          <a
            className={styles['title-link']}
            href={titleLink}
            rel="noopener noreferrer"
            target="_blank"
            data-label={label}
            data-short-label={shortLabel}
          >
            <div className={styles['arrow-out-wrapper']}>
              <IconArrowUpRight16 color="primary_100" />
            </div>
          </a>
        </div>
        <UIText1 color="gray60_100">{minGroupTotalAreaText}</UIText1>
        <UIText1>{minGroupPriceText}</UIText1>
        <div>
          <button
            data-testid="toggle-offers-group-visibility-button"
            type="button"
            onClick={toggleOffersVisibility}
            className={styles['toggle-offers-visibility-button']}
          >
            <UIText1 color="primary_100">{allOtherOffersText}</UIText1>
            <div className={styles['arrow-down-wrapper']}>
              <IconChevronDownSmall16 color="primary_100" />
            </div>
          </button>
        </div>
      </div>
      <div className={styles['divider']} />
      <div className={styles['offers-list-container']}>
        <div className={styles['offers-list-wrapper']}>
          <OffersList
            isRent={isRent}
            offers={otherOffers}
            onShowAllOffersClick={onShowAllOffersClick}
            offersCount={offersCount}
            offersLink={titleLink}
            offerGroupType={offerGroupType}
            onListItemClick={onListItemClick}
          />
        </div>
      </div>
    </div>
  );
};
