import {
  IconCar16,
  IconLike16,
  IconPin16,
  IconStore16,
  IconUser16,
  IconWalk16,
  IconWallet16,
  IconCommercialObject16,
} from '@cian/ui-kit';
import * as React from 'react';

export interface IReportFeature {
  Icon: JSX.Element;
  text: string;
}

export const REPORT_FEATURES: IReportFeature[] = [
  {
    Icon: <IconUser16 />,
    text: 'Охват населения',
  },
  {
    Icon: <IconWalk16 />,
    text: 'Пешеходный трафик',
  },
  {
    Icon: <IconCar16 />,
    text: 'Автомобильный трафик',
  },
  {
    Icon: <IconWallet16 />,
    text: 'Средний бюджет семьи по району',
  },
  {
    Icon: <IconCommercialObject16 />,
    text: 'Арендные ставки рядом',
  },
  {
    Icon: <IconPin16 />,
    text: 'Точки притяжения',
  },
  {
    Icon: <IconStore16 />,
    text: 'Конкуренты в радиусе 1 км',
  },
  {
    Icon: <IconLike16 />,
    text: 'Рекомендации по выбору места для бизнеса',
  },
];
