import { Spinner, UIHeading4 } from '@cian/ui-kit';
import cx from 'clsx';
import * as React from 'react';

import * as styles from './StatusBar.css';
import { IStatusBarProps } from './types';

export const StatusBar: React.FC<IStatusBarProps> = props => {
  const { statusMessage, hasSpinner, hasError } = props;

  if (!statusMessage) {
    return null;
  }

  return (
    <div className={cx(styles['container'], { [styles['error']]: hasError })}>
      {hasSpinner && (
        <div data-testid="spinner" className={styles['spinner-wrapper']}>
          <Spinner size={16} color="white_100" />
        </div>
      )}
      <UIHeading4 color="white_100">{statusMessage}</UIHeading4>
    </div>
  );
};

StatusBar.displayName = 'StatusBar';
