import { useIsomorphicLayoutEffect } from '@cian/react-utils';
import { IconClose16, ModalFullscreen } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './GalleryFullscreen.css';
import { TMedia } from '../../types';
import { GalleryInner } from '../GalleryInner';

interface IGalleryFullscreenProps {
  slotBefore?: React.ReactNode;
  media: TMedia[];
  initialSlide?: number;
  open?: boolean;
  onClose?(): void;
}

export const GalleryFullscreen: React.FC<React.PropsWithChildren<IGalleryFullscreenProps>> = ({
  slotBefore,
  media,
  initialSlide,
  open,
  onClose = () => {},
}) => {
  const galleryInnerRef = React.useRef<React.ElementRef<typeof GalleryInner>>(null);

  /* istanbul ignore next */
  useIsomorphicLayoutEffect(() => {
    if (open && initialSlide !== undefined) {
      requestAnimationFrame(() => {
        galleryInnerRef.current?.scrollTo(initialSlide, { behavior: 'auto' });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <ModalFullscreen escape open={!!open} theme="black" onClose={onClose}>
      <button className={styles['close']} title="Закрыть полноэкранную галерею" onClick={onClose}>
        <IconClose16 display="block" color="white_100" />
      </button>
      <div className={styles['container']}>
        {slotBefore}
        <GalleryInner
          ref={galleryInnerRef}
          media={media}
          objectFit="contain"
          thumbsBounded={true}
          isFullscreen={true}
        />
      </div>
    </ModalFullscreen>
  );
};
