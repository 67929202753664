import {
  Button,
  FormField,
  Input,
  UIHeading2,
  UIHeading3,
  UIHeading4,
  UIHeading6,
  UIText1,
  UIText2,
} from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './PurchaseForm.css';
import { WILL_HELP_FEATURES, WILL_KNOW_FEATURES } from './constants';
import { CIAN_SOCIAL_EMAIL_REGEXP, schema, useEmailValidation } from './internal';
import { IPurchaseFormProps } from './types';

export const PurchaseForm: React.FC<IPurchaseFormProps> = ({
  userEmail,
  beforePrice,
  price,
  sale,
  legalDocsUrl,
  onSubmit,
  onUpdatePaymentStatus,
  isDataLoading,
}) => {
  const [email, setEmail] = React.useState<string>('');

  const { isValidating, hasError, errorMessage, validateForm, resetError } = useEmailValidation(schema);

  const handleChangeEmail = (_: React.ChangeEvent<HTMLInputElement>, value: string) => {
    resetError();
    setEmail(value);
  };

  React.useEffect(() => {
    // email'ы, которые оканчиваются на "@social.cian.ru" не будут автоматически проставлены в форму
    // в момент ее загрузки
    if (CIAN_SOCIAL_EMAIL_REGEXP.test(userEmail)) {
      return setEmail('');
    }

    setEmail(userEmail);
  }, [userEmail]);

  React.useEffect(() => {
    onUpdatePaymentStatus();
  }, [onUpdatePaymentStatus]);

  const onSubmitForm = React.useCallback(async () => {
    const isInvalid = await validateForm(email);

    if (isValidating || isInvalid) {
      return;
    }

    onSubmit(email);
  }, [validateForm, isValidating, email, onSubmit]);

  return (
    <div className={styles['container']}>
      <div>
        <div className={styles['header-wrapper']}>
          <UIHeading2>Отчёт о привлекательности объекта</UIHeading2>
        </div>
        <div>
          <div className={styles['info-list']}>
            <div className={styles['info-list-header']}>
              <UIHeading4>Вы узнаете</UIHeading4>
            </div>
            {WILL_KNOW_FEATURES.map(({ text, Icon }) => {
              return (
                <div key={text} className={styles['info-item']}>
                  <div className={styles['icon-wrapper']}>{Icon}</div>
                  <UIText1>{text}</UIText1>
                </div>
              );
            })}
          </div>
          <div className={styles['info-list']}>
            <div className={styles['info-list-header']}>
              <UIHeading4>Это поможет</UIHeading4>
            </div>
            {WILL_HELP_FEATURES.map(({ text, Icon }) => {
              return (
                <div key={text} className={styles['info-item']}>
                  <div className={styles['icon-wrapper']}>{Icon}</div>
                  <UIText1>{text}</UIText1>
                </div>
              );
            })}
          </div>
        </div>
        <form onSubmit={e => e.preventDefault()}>
          <div className={styles['form']}>
            <FormField label="Оставьте действующий email — на него придёт отчёт" errorMessage={errorMessage}>
              <Input
                value={email}
                size="M"
                placeholder="email@pochta.xyz"
                invalid={hasError}
                onChange={handleChangeEmail}
                disabled={isDataLoading || isValidating}
              />
            </FormField>
            <div className={styles['total-wrapper']}>
              {beforePrice && price && sale && (
                <div className={styles['price-container']} data-testid="price-container-test-id">
                  <div className={styles['before-price']}>
                    <UIText2 color="gray60_100">{`${beforePrice} ₽`}</UIText2>
                  </div>

                  <div className={styles['price']}>
                    <UIHeading3>{`${price} ₽`}</UIHeading3>
                    <div className={styles['discount']}>
                      <UIHeading6 color="white_100">{`-${sale}%`}</UIHeading6>
                    </div>
                  </div>
                </div>
              )}

              <Button
                theme="fill_primary"
                type="submit"
                onClick={onSubmitForm}
                disabled={isDataLoading || isValidating}
              >
                Заказать отчёт
              </Button>
            </div>
          </div>
        </form>
      </div>
      <div className={styles['legal-docs-wrapper']}>
        <UIText1>
          Заказывая отчёт, вы принимаете условия{' '}
          <a href={legalDocsUrl} target="_blank" className={styles['link']} rel="noreferrer">
            оферты
          </a>
        </UIText1>
      </div>
    </div>
  );
};

PurchaseForm.displayName = 'PurchaseForm';
