import { Button, IconBullet16, Label, UIHeading2, UIHeading4, UIText1 } from '@cian/ui-kit';
import * as React from 'react';

import { ChartIcon } from './ChartIcon';
import { REPORT_PROPERTIES_NAMES } from './constants';
import * as style from './style.css';

export interface IAnalyticsBlockNewProps {
  addressString: string;
  reportPrice: string;
  onBuyClick: () => void;
}

export const AnalyticsBlockNew: React.FC<React.PropsWithChildren<IAnalyticsBlockNewProps>> = ({
  addressString,
  reportPrice,
  onBuyClick,
}) => {
  return (
    <div className={style['container']}>
      <div className={style['inner']}>
        <div className={style['header']}>
          <ChartIcon />

          <div className={style['title']}>
            <div className={style['top']}>
              <UIHeading2 color="black_100">Аналитический отчёт по зданию</UIHeading2>
              <Label color="error_100" background="rgba(255, 31, 52, 0.1)">
                Новое
              </Label>
            </div>

            <UIText1 color="black_100">{addressString}</UIText1>
          </div>
        </div>

        <UIHeading4 color="black_100">Что входит в PDF-отчёт</UIHeading4>

        <ul className={style['properties']}>
          {REPORT_PROPERTIES_NAMES.map((property, index) => (
            <li key={property + index}>
              <div className={style['item']}>
                <div className={style['bullet']}>
                  <IconBullet16 color="gray_icons_100" />
                </div>
                <UIText1 color="black_100">{property}</UIText1>
              </div>
            </li>
          ))}
        </ul>

        <Button theme="fill_primary" size="XS" onClick={onBuyClick}>
          Купить отчёт за {reportPrice}
        </Button>
      </div>
    </div>
  );
};
